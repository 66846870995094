// 路由文件 vue3.0 + js
import { publicPath } from '../../vue.config'
import { createRouter, createWebHashHistory } from 'vue-router'
const originalPush = createRouter.prototype.push

createRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 路由懒加载
const LoginPage = () => import('../components/LoginPage.vue')
const HomePage = () => import('../components/HomePage.vue')
const UserPage = () => import('../components/UserPage.vue')
const MessagePage = () => import('../components/MessagePage.vue')
const Welcome = () => import('../components/Welcome.vue')
const ProductDetail = () => import('../components/DetailPage.vue')
const AddDevices = () => import('../components/devices/AddDevices.vue')
const OrderList = () => import('../components/order/OrderList.vue')
const ClassNotify = () => import('../components/ClassNotify.vue')
const ClassDeleteRecord = () => import('../components/ClassDeleteRecord.vue')
const DailyScheduler = () => import('../components/DailyScheduler.vue')
const SelectClass = () => import('../components/SelectClass.vue')

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: LoginPage
  },
  {
    path: '/login',
    component: LoginPage
  },
  // Home为主页，包含了底部导航栏
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/user',
    component: UserPage
  },
  {
    path: '/message',
    component: MessagePage
  },
  {
    path: '/welcome',
    component: Welcome
  },
  {
    path: '/addDevices',
    component: AddDevices
  },
  {
    path: '/productDetail',
    component: ProductDetail
  },
  {
    path: '/orderList',
    component: OrderList
  },
  {
    path: '/classNotify',
    component: ClassNotify
  },
  {
    path: '/classDeleteRecord',
    component: ClassDeleteRecord
  },
  {
    path: '/dailyScheduler',
    component: DailyScheduler
  },
  {
    path: '/selectClass',
    component: SelectClass
  }
]

// const router = {
//   routes
// }


const router = createRouter({
  // mode: 'history', //测试用
  history: createWebHashHistory(),
  base: publicPath,
  routes
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // const fullPath = to.fullPath
  // to and from are both route objects. must call `next`.
  // to将要访问的路径 from代表从哪个路径跳转而来
  // next 是一个函数，表示放行 next() 放行 next('/login') 强制跳转到登录页面
  // 如果是登录页，直接放行即
  if (to.path === '/login') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath }// 将跳转的路由path作为参数，登录成功后跳转到该路由
    })
  }
  next()
})

export default router
