import { createApp } from 'vue'
import App from './App.vue'
// 导入NutUI
import NutUI, { showToast } from '@nutui/nutui';
import "@nutui/nutui/dist/style.css";
import { Toast } from '@nutui/nutui';
// 导入axios包
import axios from 'axios'
import VueAxios from 'vue-axios'
// 导入路由
import router from './router'
// 导入NProgress包
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Calendar,Popup } from '@nutui/nutui';

const app = createApp(App)
app.use(router)
app.use(NutUI)
app.use(Toast)
app.use(Calendar)
app.use(Popup)
app.mount('#app')

// 将axios挂载到vue的原型对象上
app.config.globalProperties.$http = axios
app.use(VueAxios, axios)
app.provide('axios',app.config.globalProperties.axios)

// 使用环境变量
const apiUrl = process.env.VUE_APP_API_URL;
const isDebug = process.env.VUE_APP_DEBUG === 'true'; // 环境变量的值是字符串类型，需根据需要转换为对应的类型

// 输出当前环境的 API 地址和调试模式
console.log(apiUrl);
console.log(isDebug);

// 组件中，可以通过 this.$apiUrl 和 this.$isDebug 访问这些环境变量。
app.config.globalProperties.$apiUrl = apiUrl;
app.config.globalProperties.$isDebug = isDebug;

// 设置axios的基础路径
axios.defaults.baseURL = apiUrl
// 在 request 拦截器中，显示进度条 NProgress.start()
// 设置axios请求拦截器
axios.interceptors.request.use(config => {
  NProgress.start()
  config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('token')// lyh
  console.log(config)
  return config
})

// 在 response 拦截器中，隐藏进度条 NProgress.done()
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
}, error => {
  if (error.response) {
    switch (error.response.status) {
      case 401:
        // 若不在登陆页面，则弹出提示框
        if (router.currentRoute.value.path !== '/login') {
          showToast.fail('登录状态已过期，请重新登录', '提示', {
            confirmButtonText: '重新登录',
            callback: () => {
              // 清除token
              window.sessionStorage.removeItem('token')
              // 跳转到登录页面
              router.replace({
                path: '/login',
                query: { redirect: router.currentRoute.value.fullPath }
              })
              // 刷新页面
              window.location.reload()
            }
          })
          break
        }
        return Promise.reject(error.response)
    }
  }
}
)
