<template>
  <div id="app">
    <router-view />
    <nut-tabbar bottom safe-area-inset-bottom placeholder @tab-switch="tabSwitch">
      <nut-tabbar-item v-for="(item, index) in List" :key="index" :tab-title="item.title" :icon="item.icon" :to="item.to">
      </nut-tabbar-item>
    </nut-tabbar>
  </div>
</template>

<script>
import { h } from 'vue';
import { Home, Category, Find, Cart, My, Date, Order, Add, Setting, Service} from '@nutui/icons-vue';

export default {
  name: 'App',
  // eslint-disable-next-line vue/no-unused-components
  components: { Home, Category, Find, Cart, My, Date, Order, Add, Setting, Service },
  mounted() {
    this.getOpenid()
  },
  setup() {
    const List = [
      {
        title: '主页',
        icon: h(Home),
        to: '/welcome',
      },
      {
        title: '课表',
        icon: h(Date),
        to: '/dailyScheduler'
      },
      {
        title: '个人',
        icon: h(My),
        to: '/user'
      }
    ];
    // 为了获取微信openid，需判断链接后是否有code参数,如果有就将其存储到sessionStorage
    const getOpenid = () => {
      console.log(window.location.search, window.location.href);
      const searchParams = new URLSearchParams(window.location.search)
      const code = searchParams.get('code')
      console.log(code);
      if (code) {
        sessionStorage.setItem('code', code)
      }
    };
    // tab切换
    const tabSwitch = (item, index) => {
      console.log(item, index);
    };
    return {
      List,
      tabSwitch,
      getOpenid
    };
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  z-index: 99;
}
</style>
