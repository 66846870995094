// const webpack = require('webpack');

module.exports = {
  publicPath: process.env.NODE_ENV === 'production' ? '/web/' : '/',
  configureWebpack: {
    plugins: [
      // new webpack.DefinePlugin({
      //   'process.env': JSON.stringify(require('dotenv').config().parsed)
      // })
    ]
  },
  devServer: {
    host: '0.0.0.0',
    disableHostCheck: true,
    port: 8089
  }
}